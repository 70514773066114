import { IconContext } from "react-icons";
import {
  FaItunes,
  FaSpotify,
  FaAmazon,
  FaSoundcloud,
  FaBandcamp,
  FaInstagram,
  FaBan,
} from "react-icons/fa6";
import { SiTidal } from "react-icons/si";
// import { InstagramEmbed } from "react-social-media-embed";

const SocialMediaLinks = () => (
  <IconContext.Provider
    value={{ color: "grey", size: "5em", className: "global-class-name" }}
  >
    <div>
      <div>
        <a
          href="https://music.apple.com/us/artist/ron-gagnon/1739860907"
          style={{ padding: "20px" }}
        >
          <FaItunes />
        </a>
        <a
          href="https://open.spotify.com/artist/0WWVaRrxt3CvCL1vtsVWn5"
          style={{ padding: "20px" }}
        >
          <FaSpotify />
        </a>
        <a
          href="https://music.amazon.com/artists/B0D135T747/ron-gagnon"
          style={{ padding: "20px" }}
        >
          <FaAmazon />
        </a>
        <a
          href="https://listen.tidal.com/artist/46929972"
          style={{ padding: "20px" }}
        >
          <SiTidal />
        </a>
        <a href="https://soundcloud.com/rongagnon" style={{ padding: "20px" }}>
          <FaSoundcloud />
        </a>
        <a href="https://rongagnon.bandcamp.com/" style={{ padding: "20px" }}>
          <FaBandcamp />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/rongagnonmusic/"
          style={{ padding: "20px" }}
        >
          <FaInstagram />
        </a>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <InstagramEmbed
          url="https://www.instagram.com/rongagnonmusic/"
          width={328}
        />
      </div> */}
    </div>
  </IconContext.Provider>
);

export default SocialMediaLinks;
