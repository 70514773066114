import { InstagramEmbed } from "react-social-media-embed";
import "./assets/css/App.css";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import SocialMediaLinks from "./components/SocialMediaLinks";
import SpotifyHighlandEmbed from "./components/SpotifyHighlandEmbed";

function App() {
  return (
    <div className="App">
      <div id="wrapper">
        <NavBar />
        <div id="top">
          <div id="sectionheader" className="section_container"></div>
          <div id="sectiontitle" className="section_container">
            <h1>Ron Gagnon</h1>
          </div>
          <div id="sectionbody" className="section_container">
            <SocialMediaLinks />
          </div>
        </div>
        <div className="spacer"></div>
        <div id="discography">
          <div id="sectionheader" className="section_container"></div>
          <div id="sectiontitle" className="section_container">
            <h1>Discography</h1>
          </div>
          <div id="sectionbody" className="section_container">
            <SpotifyHighlandEmbed />
          </div>
        </div>
        <div className="spacer"></div>
        <div id="live">
          <div id="sectionheader" className="section_container"></div>
          <div id="sectiontitle" className="section_container">
            <h1>Live</h1>
          </div>
          <div id="sectionbody" className="section_container">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <InstagramEmbed
                url="https://www.instagram.com/p/C53WUCMLCUQ/"
                width="50%"
                captioned
              />
            </div>
          </div>
        </div>
        <div className="spacer"></div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
