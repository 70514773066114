// src/components/Navbar.js
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../assets/css/NavBar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button className="menu-button" onClick={toggleMenu}>
        {isOpen ? (
          <FaTimes style={{ fontSize: "48px", color: "white" }} />
        ) : (
          <FaBars style={{ fontSize: "48px", color: "white" }} />
        )}
      </button>
      <nav className={`nav-menu ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <a href="#top" onClick={toggleMenu}>
              Top
            </a>
          </li>
          <li>
            <a href="#discography" onClick={toggleMenu}>
              Discography
            </a>
          </li>
          <li>
            <a href="#live" onClick={toggleMenu}>
              Live
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
