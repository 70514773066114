import React from "react";

const SpotifyHighlandEmbed = () => {
  return (
    <div>
      <iframe
        title="SpotifyHighlandSessions"
        src="https://open.spotify.com/embed/album/06m53GG5a5h9RyOrjZhtPQ?utm_source=generator"
        width="80%"
        height="352"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default SpotifyHighlandEmbed;
