// src/components/Footer.js
import React from "react";
import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <div id="footer">
      <div id="legal">
        &copy; 2024 rongagnon.com - All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
